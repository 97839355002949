<template>
    <!-- <b-btn
        :class="{ 'enabled': isEnabled, 'disabled': !isEnabled }"
    > -->
        <div @click="btnClicked" class="mr-2 checkbox-group">
            <label style="font-size: 12px !important;" class="position-relative mb-0" :class="{ 'filled': isEnabled }">{{ $t(labelText) }}</label>
        </div>
    <!-- </b-btn> -->
</template>

<script>
export default {
    props: {
        isEnabled: Boolean,
        defaultType: Boolean,
        merchantSponsor: Boolean,
        collectPayslip: Boolean
    },
    computed: {
        labelText() {
            if(this.merchantSponsor) {
                return 'Merchant Sponsored';
            }
            if(this.collectPayslip) {
                return 'Collect Payslip';
            }
            if(this.defaultType) {
                return this.isEnabled ? 'Default' : 'views.payment_channels.make_default';
            }
            return this.isEnabled ? 'views.payment_channels.enabled' : 'views.payment_channels.disabled';
        }
    },
    methods: {
        btnClicked() {
            this.$emit('btn-clicked')
        }
    }
}
</script>

<style scoped>
button {
    font-size: 12px !important;
    background: #fff !important;
    border: 1px solid !important;
}
.cred-section .btn{
    border-radius: 4px;
}
.enabled {
    border-color: #FC6B2D !important;
    color: #252B3A !important;
}
.disabled {
    border-color: #DAD9D9 !important;
    color: #D9D9D9 !important;
}

.checkbox-group{
  display: inline-block;
}
.checkbox-group  label {
  cursor: pointer;
  font-size: inherit !important;
  line-height: 20px;
}
.checkbox-group  label:before {
  content:'';
  --webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #FC6B2D;
  border-radius: 6px;
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
}
.checkbox-group  label.filled:before {
  background-color: rgba(252, 107, 45, 0.10);
  border: 1px solid #FC6B2D;
}

.checkbox-group label.filled:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 7px;
  width: 6px;
  height: 11px;
  border: 1px solid #FC6B2D;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.make-default-class{
    margin-left: 0px !important;
    margin-top: 10px;
    display: block;
}
</style>