<template>
<div style="display: inline-block;vertical-align: middle;">
    <label 
        class="cust-switch"
        :class="{ 'enabled': isEnabled, 'disabled': !isEnabled }"
        @click="btnClicked"
        style="margin-bottom: 0px;">
        <span class="cust-slider round" :class="{ 'filled': isEnabled }" ></span>
    </label>
</div>
</template>

<script>
export default {
    props: {
        isEnabled: Boolean,
        defaultType: Boolean,
        merchantSponsor: Boolean,
        collectPayslip: Boolean
    },
    computed: {
        labelText() {
            if(this.merchantSponsor) {
                return 'Merchant Sponsored';
            }
            if(this.collectPayslip) {
                return 'Collect Payslip';
            }
            if(this.defaultType) {
                return this.isEnabled ? 'Default' : 'views.payment_channels.make_default';
            }
            return this.isEnabled ? 'views.payment_channels.enabled' : 'views.payment_channels.disabled';
        }
    },
    methods: {
        btnClicked() {
            this.$emit('btn-clicked')
        }
    }
}
</script>

<style scoped>
.cust-switch {
  position: relative;
  display: inline-block;
  width: 47px;
  height: 24px;
  vertical-align: middle;
}

.cust-switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.cust-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #D5DBE1;
  -webkit-transition: .4s;
  transition: .4s;
}

.cust-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.cust-slider.filled {
  background-color: #FC6B2D;
}

/* input:focus + .cust-slider {
  box-shadow: 0 0 1px #2196F3;
} */

.cust-slider.filled:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}

/* Rounded sliders */
.cust-slider.round {
  border-radius: 18px;
}

.cust-slider.round:before {
  border-radius: 50%;
}
</style>